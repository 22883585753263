import {
  BlockIcon,
  ChatIcon,
  FollowIcon,
  HandShakeSimpleIcon,
  CompanyIndustryIcon,
  JobsIcon,
  ReportIcon,
  ThreeDot,
  FollowingIconV2,
} from '@mybridge/icons';
import { Tooltip } from '@mybridge/ui/tooltip';

import { CompanyIcon } from '@mybridge/icons/Company';
import { CompanyLocationIcon } from '@mybridge/icons/CompanyLocation';
import { MeetingDefaultIcon } from '@mybridge/icons/NotificationIcons';
import {
  Avatar,
  Box,
  Button,
  HStack,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@mybridge/ui';
import { forwardRef, useContext } from 'react';
import {
  getUserFullName,
  getUserLocation,
  getUserProfilePic,
  formatLocation,
  formatCommaSeparated,
} from 'v4/lib/commons';
import { MyNetworkContext } from '../network/context';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { useSelector, useDispatch } from 'react-redux';
import { UserPresenceDot } from '../user-presence-dot';
import {
  followCompanyPage,
  unFollowCompanyPage,
} from 'v4/store/actions/companyPages.actions';
import { getAllConnectionIDs } from 'v4/store/actions/connection.actions';
import { ForceSignInWrapper } from '../force-sign-in-wrapper';
import { startChatWithUser } from 'v4/store/actions/chat.actions';
export const UserDetailsPopover = forwardRef(
  (
    { user, company, children, actionProps, showPresence = false, chatRoomId, ...props },
    ref
  ) => {
    const {
      isFriend,
      handshakeUser,
      followUser,
      isHandshakeRequested,
      isFollowed,
      handshakeLoading,
      followLoading,
      setSelectedUser,
      reportUserDisc,
      blockUserDisc,
      remindHandshake,
      unfollowUser,
      allConnectionIDs,
      isCompanyFollowed,
      handleHandshakeSendRevoke
    } = useContext(MyNetworkContext);
    const { userProfileInfo } = useSelector((s) => s.userProfile);
    const { loading } = useSelector((s) => s.companyPage);
    const { startChatSingle, startMeetingSingle } = useContext(MatrixContext);
    const fullName = getUserFullName(user);
    const profilePic = getUserProfilePic(user);
    const { slug, matrix_chat_id } = user ?? {};
    // const { experiences } = user ?? {};
    // const { title: position, company_name } = experiences?.[0] ?? {};
    const { position, company_name } = user ?? {};
    const location = getUserLocation(user);
    const dispatch = useDispatch();

    // console.log(user);

    return (
      <Popover placement="top" trigger="hover" zIndex={99999} openDelay={500} {...props}>
        <PopoverTrigger zIndex={99999} >{children}</PopoverTrigger>
        <Portal>
          {company ? (
            <>
              <PopoverContent shadow="xl">
                {/* <PopoverAnchor/> */}
                <PopoverArrow />
                <PopoverBody p={4}>
                  <HStack alignItems="flex-start">
                    <HStack pr={4}>
                      <UserPresenceDot
                        user={user}
                        w="4"
                        h="4"
                        right="1"
                        bottom="1"
                        display={showPresence ? 'block' : 'none'}
                      >
                        <Avatar
                          {...(company?.slug?.length
                            ? { as: Link, href: '/c/' + company?.slug }
                            : {})}
                          name={company?.name}
                          src={company?.image}
                          size="lg"
                          loading="lazy"
                        />
                      </UserPresenceDot>
                    </HStack>
                    <Stack pt={2}>
                      <Heading
                        {...(company?.slug?.length
                          ? { as: Link, href: '/c/' + company?.slug }
                          : {})}
                        size="sm"
                      >
                        {company?.name}
                      </Heading>
                      <Stack spacing={2}>
                        {company?.country?.length ? (
                          <HStack alignItems="flex-start">
                            <Box flexShrink={0}>
                              <CompanyLocationIcon
                                color="#9F9F9F"
                                width={'16'}
                                height={'16'}
                                style={{ marginTop: '2px' }}
                              />
                            </Box>
                            <Text size="sm">{formatLocation(company)}</Text>
                          </HStack>
                        ) : (
                          ''
                        )}
                        {company?.main_industry ? (
                          <HStack alignItems="flex-start">
                            <Box flexShrink={0}>
                              <CompanyIndustryIcon
                                color="#9F9F9F"
                                width={'16'}
                                height="16"
                                style={{ marginTop: '2px' }}
                              />
                            </Box>
                            <Text size="sm">{company?.main_industry}</Text>
                          </HStack>
                        ) : (
                          ''
                        )}
                        {company?.follower_count ? (
                          <HStack alignItems="flex-start">
                            <Box flexShrink={0}>
                              <FollowIcon
                                color="#9F9F9F"
                                width={'16'}
                                height="16"
                                style={{ marginTop: '2px' }}
                              />
                            </Box>
                            <Text size="sm">{company?.follower_count}</Text>
                          </HStack>
                        ) : (
                          ''
                        )}
                      </Stack>
                      <Stack>
                        <ForceSignInWrapper>
                          {/* <PopoverFooter p={4}> */}

                          <HStack>
                            {isCompanyFollowed?.(company) ? (
                              <IconButton
                                onClick={(e) => {
                                  dispatch(unFollowCompanyPage(company?.id));
                                  dispatch(getAllConnectionIDs());
                                }}
                                isLoading={loading}
                                // isDisabled={isFollowed?.(user)}
                                // variant="primary"
                                icon={
                                  <FollowingIconV2 width="20" height="20" />
                                }
                                w="70px"
                              />
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  dispatch(followCompanyPage(company?.id));
                                  dispatch(getAllConnectionIDs());
                                }}
                                isLoading={loading}
                                // isDisabled={isFollowed?.(user)}
                                // variant="primary"
                                icon={<FollowIcon width="20" height="20" />}
                                w="70px"
                              />
                            )}

                            <Menu>
                              <MenuButton as={IconButton}>
                                <HStack justifyContent="center">
                                  <ThreeDot width="20" height="20" />
                                </HStack>
                              </MenuButton>
                              <MenuList>
                                <MenuItem
                                  onClick={(e) => {
                                    setSelectedUser?.(user);
                                    reportUserDisc?.onOpen?.();
                                  }}
                                  icon={<ReportIcon width="20" height="20" />}
                                >
                                  Report
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    setSelectedUser?.(user);
                                    blockUserDisc?.onOpen?.();
                                  }}
                                  icon={<BlockIcon width="20" height="20" />}
                                >
                                  Block
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </HStack>
                          {/* </PopoverFooter> */}
                        </ForceSignInWrapper>
                      </Stack>
                    </Stack>
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </>
          ) : (
            <>
              {userProfileInfo?.id === user?.id ? (
                <></>
              ) : (
                <PopoverContent zIndex={99999}  shadow="xl">
                  {/* <PopoverAnchor/> */}
                  <PopoverArrow />
                  <PopoverBody zIndex={99999}  p={4}>
                    <HStack zIndex={99999}  alignItems="flex-start">
                      <HStack zIndex={99999}  pr={4}>
                        <UserPresenceDot
                          user={user}
                          w="4"
                          h="4"
                          right="1"
                          bottom="1"
                          display={showPresence ? 'block' : 'none'}
                          zIndex={99999} 
                        >
                          <Avatar
                            {...(slug?.length
                              ? { as: Link, href: '/p/' + slug }
                              : {})}
                            name={fullName}
                            src={profilePic}
                            size="lg"
                          />
                        </UserPresenceDot>
                      </HStack>
                      <Stack pt={2}>
                        <Heading
                          {...(slug?.length
                            ? { as: Link, href: '/p/' + slug }
                            : {})}
                          size="sm"
                        >
                          {fullName}
                        </Heading>
                        <Stack spacing={2}>
                          {position?.length ? (
                            <HStack alignItems="flex-start">
                              <Box flexShrink={0}>
                                <JobsIcon
                                  color="#9F9F9F"
                                  width={'16'}
                                  height="16"
                                  style={{ marginTop: '2px' }}
                                />
                              </Box>
                              <Text size="sm">{position}</Text>
                            </HStack>
                          ) : (
                            ''
                          )}
                          {company_name?.length ? (
                            <HStack alignItems="flex-start">
                              <Box flexShrink={0}>
                                <CompanyIcon
                                  color="#9F9F9F"
                                  width={'16'}
                                  height="16"
                                  style={{ marginTop: '2px' }}
                                />
                              </Box>
                              <Text size="sm">{company_name}</Text>
                            </HStack>
                          ) : (
                            ''
                          )}
                          {location?.length ? (
                            <HStack alignItems="flex-start">
                              <Box flexShrink={0}>
                                <CompanyLocationIcon
                                  color="#9F9F9F"
                                  width={'16'}
                                  height="16"
                                  style={{ marginTop: '2px' }}
                                />
                              </Box>
                              <Text size="sm">{location}</Text>
                            </HStack>
                          ) : (
                            ''
                          )}
                        </Stack>
                        <Stack>
                          <ForceSignInWrapper>
                            {/* <PopoverFooter p={4}> */}
                            {isFriend?.(user) ? (
                              <HStack>
                                <IconButton
                                  // onClick={(e) => startChatSingle?.(user)}
                                  onClick={(e) => {
                                    chatRoomId
                                      ? dispatch(startChatWithUser(chatRoomId))
                                      : startChatSingle?.(user);
                                  }}
                                  icon={<ChatIcon width="20" height="20" />}
                                  w="70px"
                                />
                                <IconButton
                                  onClick={(e) => startMeetingSingle?.(user)}
                                  icon={
                                    <Tooltip variant="round" label="Add Gif">
                                      <MeetingDefaultIcon
                                        width="20"
                                        height="20"
                                      />
                                    </Tooltip>
                                  }
                                  w="70px"
                                />
                                <Menu>
                                  <MenuButton as={IconButton}>
                                    <HStack justifyContent="center">
                                      <ThreeDot width="20" height="20" />
                                    </HStack>
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={(e) => {
                                        setSelectedUser?.(user);
                                        reportUserDisc?.onOpen?.();
                                      }}
                                      icon={
                                        <ReportIcon width="20" height="20" />
                                      }
                                    >
                                      Report User
                                    </MenuItem>
                                    <MenuItem
                                      onClick={(e) => {
                                        setSelectedUser?.(user);
                                        blockUserDisc?.onOpen?.();
                                      }}
                                      icon={
                                        <BlockIcon width="20" height="20" />
                                      }
                                    >
                                      Block User
                                    </MenuItem>
                                  </MenuList>
                                </Menu>
                              </HStack>
                            ) : (
                              <HStack>
                                {isHandshakeRequested?.(user) ? (
                                <Tooltip label="Withdraw Handshake">
                                  <IconButton
                                    onClick={(e) => handleHandshakeSendRevoke?.(user)}
                                    isLoading={handshakeLoading}
                                    // isDisabled={isHandshakeRequested?.(user)}
                                    icon={
                                      <HandShakeSimpleIcon
                                        color="#F5AEAD"
                                        width="20"
                                        height="20"
                                      />
                                    }
                                    
                                    w="70px"
                                  />
                                  </Tooltip>
                                ) : (
                                  <Tooltip label="Send Handshake Request">
                                  <IconButton
                                    onClick={(e) => handshakeUser?.(user)}
                                    isLoading={handshakeLoading}
                                    w="70px"
                                    // isDisabled={isHandshakeRequested?.(user)}
                                    icon={
                                      <HandShakeSimpleIcon
                                        color="green"
                                        width="20"
                                        height="20"
                                      />
                                    }
                                  />
                                  </Tooltip>
                                )}

                                {/* {isFollowed?.(user) ? (
                                  <IconButton
                                    onClick={(e) => unfollowUser?.(user)}
                                    isLoading={followLoading}
                                    // isDisabled={isFollowed?.(user)}
                                    // variant="primary"
                                    icon={
                                      <FollowingIconV2
                                        // color="white"
                                        width="20"
                                        height="20"
                                      />
                                    }
                                    w="70px"
                                    padding={0}
                                  />
                                ) : (
                                  <IconButton
                                    onClick={(e) => followUser?.(user)}
                                    isLoading={followLoading}
                                    // isDisabled={isFollowed?.(user)}
                                    // variant="primary"
                                    icon={
                                      <FollowIcon
                                        // color="white"
                                        width="20"
                                        height="20"
                                      />
                                    }
                                    w="70px"
                                  />
                                )} */}

                                {/* <IconButton>
                    <ThreeDot width="20" height="20" />
                  </IconButton> */}
                  <Menu>
                              <MenuButton as={IconButton}>
                                <HStack justifyContent="center">
                                  <ThreeDot width="20" height="20" />
                                </HStack>
                              </MenuButton>
                              <MenuList>
                                <MenuItem
                                  onClick={(e) => {
                                    setSelectedUser?.(user);
                                    reportUserDisc?.onOpen?.();
                                  }}
                                  icon={<ReportIcon width="20" height="20" />}
                                >
                                  Report
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => {
                                    setSelectedUser?.(user);
                                    blockUserDisc?.onOpen?.();
                                  }}
                                  icon={<BlockIcon width="20" height="20" />}
                                >
                                  Block
                                </MenuItem>
                              </MenuList>
                            </Menu>
                              </HStack>
                            )}
                            {/* </PopoverFooter> */}
                          </ForceSignInWrapper>
                        </Stack>
                      </Stack>
                    </HStack>
                  </PopoverBody>
                </PopoverContent>
              )}
            </>
          )}
        </Portal>
      </Popover>
    );
  }
);
